:root {
  --font-poppins: 'Poppins';
  --accent-color: #ffee00;
  --primary-900-color: #24236a;
  --text-accent-color: var(--primary-900-color);
  --header-divider-color: #37367d;
  --text-header-color: #fff;
  --icon-filter-color: brightness(0) saturate(100%) invert(100%) sepia(96%) saturate(19%) hue-rotate(215deg) brightness(104%) contrast(100%);
  --header-icon-filter-color: var(--icon-filter-color);
  --selected-color: var(--primary-900-color);
  --background-color: var(--primary-900-color);
  --text-body-color: #fff;
  --section-background-color: var(--primary-900-color);
  --button-background-color: var(--accent-color);
  --button-border-radius: 12px;
  --partners-divider-color: #d9d9d9;
  --footer-link-color: var(--primary-900-color);
  --mobile-footer-link-color: var(--primary-900-color);
  --select-label-color: var(--primary-900-color);
  --select-value-color: var(--primary-900-color);
  font-feature-settings: 'ss01' on;
}

main {
  font-family: var(--font-poppins);
  color: var(--primary-900-color);
}

.heading1,
.heading2,
.heading3,
.heading4 {
  font-weight: 700;
}

.sectionTitle {
  font-size: 34px;

  @media (min-width: 400px) {
    font-size: 40px;
    line-height: 110%;
  }
}

.heading1 {
  font-size: 28px;
  line-height: 110%; /* 30.8px */
  letter-spacing: -0.56px;

  @media (min-width: 600px) {
    font-size: 48px;
    letter-spacing: -0.96px;
  }
}

.heading2 {
  font-size: 40px;
  letter-spacing: -1.6px;
}

.heading3 {
  font-size: 24px;
  line-height: 28px;

  @media (min-width: 600px) {
    font-size: 36px;
    line-height: 48px;
  }
}

.heading4 {
  font-size: 18px;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.54px;

  @media (min-width: 600px) {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.6px;
  }
}

.bodyLarge {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;
}
